import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Blockchain from '../../assets/images/icons/blockchain-b.svg';
import Flag from '../../assets/images/icons/flag.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import RewardA from '../../assets/images/icons/reward.svg';
import RewardB from '../../assets/images/icons/reward-b.svg';
import Touch from '../../assets/images/icons/touch.svg';

const Loyalty = () => {
  return (
    <PageLayout>
      <SEO
        title="Reinvent Your Existing Enterprise Loyalty and Rewards Systems"
        description="Enterprise Loyalty and Rewards Programs. Discover new modeling, retention, and loyalty incentive methods for improved customer satisfaction."
      />
      <Layout dark background="blue">
        <Hero type="code">
          <div className="caption">Loyalty &amp; Rewards</div>
          <Title color="white">Reinvent Your Existing Enterprise Loyalty &amp; Rewards Systems</Title>
          <Description color="blue-light">
            Discover new modeling, retention, and loyalty incentive methods for improved customer
            satisfaction.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Touch,
                title: 'Customer Engagement',
                body:
                  'Re-engage your existing loyalty & rewards program users, or attract new brand ambassadors, by adding the benefits of blockchain technologies.',
              },
              {
                Icon: Blockchain,
                title: 'Tokenization',
                body:
                  'Be ready for a tokenized digital world, but start without tokenization and use traditional points.',
              },
              {
                Icon: Flag,
                title: 'Differentiate',
                body:
                  'Stand out from competitors by offering new innovative loyalty & rewards solutions to your brand ambassadors.',
              },
              {
                Icon: RewardA,
                title: 'Loyalty as a Service',
                body:
                  'Rather than create and maintain your own blockchain infrastructure from scratch, take advantage of Blockchain as a Service.',
              },
              {
                Icon: Flexible,
                title: 'Interoperability',
                body:
                  'Integrate current and future systems, blockchains, or software. No migration concerns today or tomorrow.',
              },
              {
                Icon: RewardB,
                title: 'Incentives',
                body: 'Unique, flexible, customizable incentives to model LaaS to your enterprise needs.',
              },
            ]}
          />
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="What can tokenized micro-licenses do for loyalty &amp; reward programs?">
                  Benefit from our immutable time capabilities as a means to measure and reward loyalty. This
                  system allows you to reward customers for their time and create more transparent and
                  reliable loyalty programs. With TIME, you are able to design a robust tokenomics strategy
                  through the use of time-based loyalty incentives. Rewards based upon time itself can be
                  applied in a variety of ways; embedded into the tokenized micro-license itself, as a score
                  on top of a token, and even without the need of a token.
                </Accordion.Item>
                <Accordion.Item title="Can our developers integrate this into our existing programs?">
                  Easily, in any coding language they already know. Integrate with all traditional systems and
                  applications you already have or future ones. Including any blockchain you might ever use in
                  the future, other than Dragonchain.
                </Accordion.Item>
                <Accordion.Item title="What if we’re looking to build a new blockchain-based loyalty &amp; reward system from scratch?">
                  Perfect, we’re ready to help if you want!
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Improve your customer engagement">
                Ready to implement additional utility to your new or existing loyalty &amp; rewards program?
                Schedule a free consultation with the creators to ask anything.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Loyalty;
